/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * raleway:
 *   - http://typekit.com/eulas/00000000000000003b9b13b9
 *   - http://typekit.com/eulas/00000000000000003b9b13b3
 *   - http://typekit.com/eulas/00000000000000003b9b13b5
 *   - http://typekit.com/eulas/00000000000000003b9b13b1
 *   - http://typekit.com/eulas/00000000000000003b9b13b6
 *   - http://typekit.com/eulas/00000000000000003b9b13b0
 *   - http://typekit.com/eulas/00000000000000003b9b13b2
 *   - http://typekit.com/eulas/00000000000000003b9b13b4
 *   - http://typekit.com/eulas/00000000000000003b9b13b7
 *   - http://typekit.com/eulas/00000000000000003b9b13b8
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-08-09 08:42:08 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=akk6lny&ht=tk&f=18456.18457.18458.18459.22705.37554.37555.37556.37557.37558&a=82907017&app=typekit&e=css');

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/73e1a8/00000000000000003b9b13b9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/73e1a8/00000000000000003b9b13b9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/73e1a8/00000000000000003b9b13b9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/8e698b/00000000000000003b9b13b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8e698b/00000000000000003b9b13b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8e698b/00000000000000003b9b13b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/11282a/00000000000000003b9b13b5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/11282a/00000000000000003b9b13b5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/11282a/00000000000000003b9b13b5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/74d1b5/00000000000000003b9b13b1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/74d1b5/00000000000000003b9b13b1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/74d1b5/00000000000000003b9b13b1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/aad4b2/00000000000000003b9b13b6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/aad4b2/00000000000000003b9b13b6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/aad4b2/00000000000000003b9b13b6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/0b59d6/00000000000000003b9b13b0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/0b59d6/00000000000000003b9b13b0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0b59d6/00000000000000003b9b13b0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/f99f28/00000000000000003b9b13b2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f99f28/00000000000000003b9b13b2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f99f28/00000000000000003b9b13b2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/b0477e/00000000000000003b9b13b4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b0477e/00000000000000003b9b13b4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b0477e/00000000000000003b9b13b4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/bc1760/00000000000000003b9b13b7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/bc1760/00000000000000003b9b13b7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/bc1760/00000000000000003b9b13b7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('https://use.typekit.net/af/804528/00000000000000003b9b13b8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/804528/00000000000000003b9b13b8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/804528/00000000000000003b9b13b8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.3s;
}

h1 {
  font-family: 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 600;
}

h3 {
  font-family: 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.text-input {
  padding: 0.6em 0.7em;
  border-radius: 0.35em;
}

.input-shadow {
  transition: all 0.4s;
  background-color: #f6f6f6;
}

.input-complete {
  background-color: transparent;
}

.is-invalid {
  border: 1px solid #f44336 !important;
}

.faded {
  opacity: 0.3;
}

.checkbox {
  border: 0 !important;
}

.checkbox:checked {
  background-color: #cc1414;
}

.banner {
  background: rgb(229, 69, 69);
  background: linear-gradient(
    90deg,
    rgba(229, 69, 69, 1) 0%,
    rgba(204, 20, 20, 1) 100%
  );
}

a {
  transition: 0.2s all ease-in-out;
}

a:hover {
  color: #005bbb;
}

button,
[type='submit'],
[type='button'] {
  background: #005bbb;
  background: linear-gradient(180deg, #1168c5 0%, #0052aa 100%);
  font-family: 'raleway', sans-serif;
  font-weight: 600;
  padding: 0.25em 1em 0.4em 1em;
  color: #fff;
  font-size: 1em;
  border-radius: 0.35em;

  transition: all 0.2s;
}

button:hover {
  background: #005bbb;
  color: #fff;
  background: linear-gradient(180deg, #0d57a7 0%, #004288 100%);
}

button.white {
  background: #eee;
  color: #005bbb;
  background: linear-gradient(180deg, #fff 0%, #c9c9c9 100%);
}

button.white:hover {
  background: #ccc;
  color: #005bbb;
  background: linear-gradient(180deg, #eee 0%, #bbb 100%);
}

button.disabled {
  background: rgb(100, 100, 100);
  background: linear-gradient(180deg, #d1d1d1 0%, #b1b1b1 100%);
}

.form-control1 {
  border: 3px solid rgba(0, 0, 0, 0);
}
.is-invalid1 {
  border: 3px solid rgb(229, 69, 69);
}

.invalid-feedback {
  color: rgb(229, 69, 69);
}

.transition {
  transition: all 0.5s;
}
