.datepicker_header-wrapper
  display: flex
  flex-direction: row
  justify-content: center
  align-items: strech
  padding-bottom: .5rem

.btn-manipulate-date
  padding: 0 .8em 0 .8em
  background-color: transparent
  border: 0
  // border: 2px solid #ff5745
  border-radius: 0
  color: #005bbb
  font-size: 1.3em
  cursor: pointer

.react-datepicker
  border-color: #005bbb
  border-radius: 5px
  background-color: #eee
  z-index: 100000000
  box-shadow: 7px 10px 36px rgba(0, 0, 0, 0.6)
  font-size: .98em

.react-datepicker-wrapper
  // border: 1px solid #005bbb
  border-image-width: 0
  margin: 0 1em 0 1em

.container-wrap input[type="text"]
  // border: 1px solid #005bbb
  font-size: 1.05em
  cursor: pointer
  padding-left: 0
  padding-right: 0
  pointer: cursor

  // border-top: 1px solid #666
  // border-bottom: 1px solid #666

.react-datepicker__input-container input
  text-align: center
  border: 1px solid #005bbb
  font-weight: 500
  border-color: #005bbb
  padding-top: 0.3em
  padding-bottom: 0.3em
  border-radius: 0.2em
  cursor: pointer

.react-datepicker__input-container input:hover
  color: #005bbb

@media only screen and (min-width: 680px)
  .react-datepicker__input-container input
    font-size: 1.15em

@media only screen and (min-width: 1200px)
  .react-datepicker__input-container input
    font-size: 1.3em

.react-datepicker__header
  background-color: #ddd
  padding-top: 0.7em

.react-datepicker__month
  margin: .45em .6em .6em .6em

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
  color: #000
  font-size: 1em

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before
  border-color: #005bbb

.react-datepicker__year-read-view--down-arrow:hover, .react-datepicker__month-read-view--down-arrow:hover, .react-datepicker__month-year-read-view--down-arrow:hover, .react-datepicker__navigation-icon::before:hover
  border-color: #bbb

.react-datepicker__day-names
  margin-top: 1.1em

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
  color: #000
  width: 2.17em
  line-height: 2.12em
  margin: 0.166em

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover
  border-radius: 0.3rem
  background-color: #ffd500

.react-datepicker__day
  color: #000
  font-weight: 500

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled
  color: #aaa
  font-weight: 300

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
  background-color: #005bbb

.react-datepicker__day--keyboard-selected
  background-color: inherit

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after
  border-top-color: #005bbb
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after
  border-bottom-color: #005bbb

.app_opening-hours-times
  margin-top: 1em
  font-size: 1.3em

.react-datepicker__navigation
  top: 0.2em

  // line-height: 1.7em
  // border: 0.45em solid transparent

.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous
  left: 0
  top: .2em

.react-datepicker__navigation--previous
  // border-right-color: #ccc
  left: .5em

.react-datepicker__navigation--next
  // border-left-color: #ccc
  right: .5em
